import './App.css';
import MainPage from './components/MainPage';
import LeaveReview from './ LeaveReview'


function App() {
  return (
    <>
   <LeaveReview/>
   <MainPage/>
   </>
  );
}

export default App;
